@mixin media($breakpoint) {
  @if $breakpoint == sm {
    @media (min-width: map-get($grid-breakpoints, sm)) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media (min-width: map-get($grid-breakpoints, md)) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media (min-width: map-get($grid-breakpoints, lg)) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media (min-width: map-get($grid-breakpoints, xl)) {
      @content;
    }
  }

  @if $breakpoint == xxl {
    @media (min-width: map-get($grid-breakpoints, xxl)) {
      @content;
    }
  }
}
