.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  margin-bottom: 1.5rem;
}

.phone {
  margin-bottom: 1.5rem;
}
