.entertainment {
  position: relative;

  background-repeat: no-repeat;
  background-size: 269px, 0;
  background-position-x: -15rem, 0;
  background-position-y: 80%, 0;

  &__content {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 34px;
  }

  &__title {
    align-self: center;
    max-width: 227px;
    margin-bottom: 21px;

    font-size: 3rem;
    font-weight: 600;
    line-height: 0.9;
    text-align: center;
    letter-spacing: -0.04em;

    span {
      color: map-get($colors, main);
    }
  }

  &__text {
    font-size: 1.8rem;
    line-height: 1.315;
    text-align: center;
  }

  &__slider {
    position: relative;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 90%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    width: 4rem;
    height: 4rem;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 1.4rem;
      height: 1.4rem;

      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: translate(-50%, -50%) rotate(45deg);

      content: '';
    }

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;

    width: 4rem;
    height: 4rem;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 1.4rem;
      height: 1.4rem;

      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: translate(-50%, -50%) rotate(-135deg);

      content: '';
    }

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  &__pagination {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 14px;

    display: flex;
    column-gap: 0.5rem;

    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 0.5rem;
      height: 0.5rem;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.47);

      &-active {
        background-color: #fff;
      }
    }
  }

  @include media(md) {
    background-size: 269px, 269px;
    background-position-x: -19rem, 110%;
    background-position-y: 40%, -20%;

    &__content {
      display: flex;
      align-items: flex-start;
      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    &__block {
      width: 52%;
      margin-bottom: 0;
      padding-right: 6.5rem;
    }

    &__title {
      align-self: flex-start;
      margin-bottom: 8rem;

      font-size: 5.2rem;
      text-align: left;
      line-height: 0.87;
    }

    &__text {
      text-align: left;
    }

    &__slider {
      width: 48%;
    }

    &__slide {
      padding-bottom: 109%;
    }

    &__pagination {
      bottom: 18px;

      column-gap: 0.8rem;

      .swiper-pagination-bullet {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }

  @include media(xl) {
    background-position-x: -19rem, 100%;
    background-position-y: 90%, 20%;

    &__content {
      padding-top: 15.5em;
      padding-bottom: 15.3em;
    }

    &__block {
      width: 34%;
      padding-right: 8.7rem;
    }

    &__title {
      max-width: 30rem;
      margin-bottom: 10.6rem;

      font-size: 6.8rem;
      line-height: 0.83;
    }

    &__slider {
      width: 66%;
    }

    &__slide {
      padding-bottom: 60%;
    }

    &__prev {
      left: 1rem;

      &::before {
        left: 70%;

        width: 2.8rem;
        height: 2.8rem;
      }
    }

    &__next {
      right: 1rem;

      &::before {
        left: 30%;

        width: 2.8rem;
        height: 2.8rem;
      }
    }
  }
}

// .entertainment {
//   &__content {
//     padding-top: 6rem;
//     padding-bottom: 6rem;
//   }

//   &__title {
//     margin-bottom: 2.4rem;

//     font-size: 3rem;
//     font-weight: 600;
//     line-height: 1.87;
//     letter-spacing: -0.04em;
//   }

//   &__small {
//     position: relative;

//     display: flex;
//     width: 33%;
//     margin-bottom: 41px;
//     padding-bottom: calc(33% * 0.98);

//     img {
//       position: absolute;
//       top: 0;
//       left: 0;

//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//     }
//   }

//   &__text {
//     margin-bottom: 3.6rem;

//     font-size: 1.8rem;
//     line-height: 1.315;

//     color: #0c0c0c;
//   }

//   &__big {
//     position: relative;

//     display: flex;
//     padding-bottom: 50%;

//     img {
//       position: absolute;
//       top: 0;
//       left: 0;

//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//     }
//   }

//   @include media(md) {
//     &__content {
//       display: grid;
//       grid-template-areas:
//         'left top'
//         'left center'
//         'left bottom';
//       grid-template-columns: 21.5rem 1fr;
//       grid-column-gap: 8.5rem;
//       padding-top: 8rem;
//       padding-bottom: 8rem;
//     }

//     &__title {
//       margin-bottom: 1.5rem;

//       grid-area: top;
//       font-size: 5.2rem;
//       line-height: 1;
//     }

//     &__small {
//       grid-area: left;
//       align-self: start;
//       width: 100%;
//       margin-bottom: 0;
//       padding-bottom: 98%;
//     }

//     &__text {
//       grid-area: center;
//       max-width: 30rem;
//       margin-bottom: 9rem;
//     }

//     &__big {
//       grid-area: bottom;
//       padding-bottom: 42%;
//     }
//   }

//   @include media(xl) {
//     &__content {
//       padding-top: 15.5em;
//       padding-bottom: 15.3em;
//     }

//     &__title {
//       margin-bottom: 6.3rem;

//       font-size: 6.8rem;
//     }

//     &__text {
//       max-width: 37rem;
//       margin-bottom: 6.5rem;
//     }

//     &__big {
//       padding-bottom: 20%;
//     }
//   }
// }
