.module {
  padding: 1rem;

  background-color: #fdfcfb;
  box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);

  :global {
    .rdp {
      margin: 0;
    }

    .rdp-caption {
      position: relative;
    }

    .rdp-caption_label {
      position: absolute;
      top: 50%;
      left: 50%;

      font-size: 1.8rem;
      font-weight: 700;

      transform: translate(-50%, -50%);
    }

    .rdp-nav {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .rdp-head_cell {
      font-size: 1.4rem;
      font-weight: 400;
      text-transform: lowercase;

      color: rgba(126, 126, 126, 0.58);
    }

    .rdp-cell {
      font-size: 1.6rem;
      font-weight: 400;
    }

    .rdp-day_today {
      border-radius: 0;
      color: #fff;
      background-color: #b28975;
    }

    .rdp-day_selected {
      border-radius: 0;
      color: #000;
      background-color: transparent;
    }

    .rdp-day_disabled {
      color: #ccc;
    }
  }
}
