.module {
  position: relative;

  width: 585px;
  max-width: 100%;
  padding-top: 57px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 68px;
  overflow: hidden;

  background-color: #f4f0ed;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 273px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 2.4rem;

  font-size: 3.2rem;
  font-weight: 600;
  line-height: 0.9;
  text-align: center;
  letter-spacing: -0.04em;
}

.grade {
  margin-bottom: 3.8rem;
}

.gradeTitle {
  margin-bottom: 16px;

  font-size: 14px;
  line-height: 1.21;
  text-align: center;
  text-transform: uppercase;

  color: rgba(0, 0, 0, 0.37);
}

.gradeList {
  display: flex;
  justify-content: space-between;

  label {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  input {
    margin-bottom: 7px;
  }

  span {
    font-size: 12px;
    font-weight: 300;
    line-height: 1.17;

    color: rgba(0, 0, 0, 0.54);
  }
}

.name {
  margin-bottom: 1.5rem;
}

.email {
  margin-bottom: 1.5rem;
}

.text {
  margin-bottom: 1.5rem;
}

.policy {
  margin-bottom: 1.5rem;
}

.button {
  align-self: center;
  width: 100%;
  height: 5.6rem;

  font-weight: 600;
  font-size: 1.6rem;
  text-align: center;

  border: none;
  color: #fff;
  background-color: #b28975;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#b28975, 10%);
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
