.advantages {
  background-size: cover;

  &__content {
    display: flex;
    flex-direction: column;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &__title {
    align-self: center;
    max-width: 23rem;
    margin-bottom: 3.9rem;

    font-size: 3rem;
    font-weight: 600;
    line-height: 0.9;
    text-align: center;
    letter-spacing: -0.04em;

    span {
      color: map-get($colors, main);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 2.5rem;

    list-style: none;
  }

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 5.5rem;
    height: 5.5rem;
    margin-bottom: 1.5rem;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.78);
  }

  &__name {
    margin-bottom: 1.5rem;

    font-size: 2rem;
    font-weight: 600;
    line-height: 1.25;
    text-align: center;
    letter-spacing: -0.04em;
  }

  &__text {
    font-size: 1.5rem;
    line-height: 1.3852;
    text-align: center;
  }

  @include media(md) {
    &__content {
      padding-top: 8.5rem;
      padding-bottom: 10rem;
    }

    &__title {
      max-width: none;
      margin-bottom: 5.6rem;

      font-size: 5.2rem;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 3rem;
    }

    &__item {
      width: calc(100% / 3 - 3rem * 2 / 3);
    }
  }

  @include media(xl) {
    &__content {
      padding-top: 15.3rem;
      padding-bottom: 15.5rem;
    }

    &__title {
      margin-bottom: 7.6rem;

      font-size: 6.8rem;
    }

    &__list {
      gap: 8rem;
    }

    &__item {
      width: calc(33.3333333333% - 8rem);
    }
  }
}
