.module {
  position: relative;

  width: 755px;
  max-width: 100%;
  padding-top: 74px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 51px;
  overflow: hidden;

  background-color: #f4f0ed;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.image {
  display: flex;
  aspect-ratio: 1 / 0.51;
  margin-bottom: 44px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.name {
  margin-bottom: 24px;
  padding-bottom: 23px;

  font-size: 30px;
  font-weight: 300;
  line-height: 1.17;

  border-bottom: 1px solid #c8c8c8;
}

.text {
  margin-bottom: 46px;

  font-size: 15px;
  font-weight: 300;
  line-height: 1.42;

  color: rgba(0, 0, 0, 0.8);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 277px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;

  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;

  color: #fff;
  background-color: #bf9570;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#bf9570, 10%);
  }
}

@media (min-width: 768px) {
  .module {
    padding-left: 59px;
    padding-right: 59px;
  }
}

@media (min-width: 1200px) {
}
