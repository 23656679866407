.about {
  position: relative;

  #about {
    position: absolute;
    top: calc(-5rem + 17.6rem);
  }

  background-repeat: no-repeat;
  background-size: 269px, 0;
  background-position-x: -15rem, 0;
  background-position-y: 80%, 0;

  &__content {
    padding-top: 17.6rem;
    padding-bottom: 6rem;
  }

  &__block {
    display: flex;
    flex-direction: column;
    margin-bottom: 34px;
  }

  &__title {
    align-self: center;
    max-width: 227px;
    margin-bottom: 21px;

    font-size: 3rem;
    font-weight: 600;
    line-height: 0.9;
    text-align: center;
    letter-spacing: -0.04em;

    span {
      color: map-get($colors, main);
    }
  }

  &__text {
    font-size: 1.8rem;
    line-height: 1.315;
    text-align: center;
  }

  &__slider {
    position: relative;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 90%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;

    width: 4rem;
    height: 4rem;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 1.4rem;
      height: 1.4rem;

      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: translate(-50%, -50%) rotate(45deg);

      content: '';
    }

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;

    width: 4rem;
    height: 4rem;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 1.4rem;
      height: 1.4rem;

      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      transform: translate(-50%, -50%) rotate(-135deg);

      content: '';
    }

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  &__pagination {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 14px;

    display: flex;
    column-gap: 0.5rem;

    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 0.5rem;
      height: 0.5rem;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.47);

      &-active {
        background-color: #fff;
      }
    }
  }

  @include media(md) {
    background-size: 269px, 269px;
    background-position-x: -19rem, 110%;
    background-position-y: 40%, -20%;

    #about {
      top: calc(-7.3rem + 12.4rem);
    }

    &__content {
      display: flex;
      align-items: flex-start;
      padding-top: 12.4rem;
      padding-bottom: 5.3rem;
    }

    &__block {
      width: 52%;
      margin-bottom: 0;
      padding-right: 6.5rem;
    }

    &__title {
      align-self: flex-start;
      margin-bottom: 8rem;

      font-size: 5.2rem;
      text-align: left;
      line-height: 0.87;
    }

    &__text {
      text-align: left;
    }

    &__slider {
      width: 48%;
    }

    &__slide {
      padding-bottom: 109%;
    }

    &__pagination {
      bottom: 18px;

      column-gap: 0.8rem;

      .swiper-pagination-bullet {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }

  @include media(xl) {
    background-position-x: -19rem, 100%;
    background-position-y: 90%, 20%;

    #about {
      top: calc(-9.7rem + 20.2rem);
    }

    &__content {
      padding-top: 20.2rem;
      padding-bottom: 15.1rem;
    }

    &__block {
      width: 34%;
      padding-right: 8.7rem;
    }

    &__title {
      max-width: 30rem;
      margin-bottom: 10.6rem;

      font-size: 6.8rem;
      line-height: 0.83;
    }

    &__slider {
      width: 66%;
    }

    &__slide {
      padding-bottom: 60%;
    }

    &__prev {
      left: 1rem;

      &::before {
        left: 70%;

        width: 2.8rem;
        height: 2.8rem;
      }
    }

    &__next {
      right: 1rem;

      &::before {
        left: 30%;

        width: 2.8rem;
        height: 2.8rem;
      }
    }
  }
}
