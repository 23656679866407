.module {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 585px;
  max-width: 100%;
  padding-top: 73px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 78px;

  background-color: #f4f0ed;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.icon {
  position: relative;

  width: 54px;
  aspect-ratio: 1;
  margin-bottom: 38px;

  border-radius: 50%;
  background-color: #fff;

  &::before {
    position: absolute;
    top: 19px;
    left: 17px;

    width: 21px;
    height: 11px;

    border-left: 1px solid #b28975;
    border-bottom: 1px solid #b28975;
    transform: rotate(-45deg);

    content: '';
  }
}

.title {
  margin-bottom: 1.8rem;

  font-size: 3.2rem;
  font-weight: 600;
  line-height: 1.23;
  text-align: center;

  span {
    color: #b28975;
  }
}

.subtitle {
  max-width: 270px;

  font-size: 1.5rem;
  line-height: 1.55;
  text-align: center;
}

@media (min-width: 1200px) {
}
