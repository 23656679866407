.name {
  margin-bottom: 1.5rem;
}

.phone {
  margin-bottom: 1.5rem;
}

.policy {
  margin-bottom: 1.5rem;
}

.button {
  width: 100%;
  height: 5.6rem;

  font-size: 1.6rem;
  font-weight: 500;

  border: none;
  color: #fff;
  background-color: #b28975;

  transition: 0.2s;

  &:hover {
    background-color: darken(#b28975, 10%);
  }
}
