.module {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.adults {
  margin-bottom: 1.5rem;
}

.babies {
  margin-top: 1.5rem;
}
