.module {
  position: relative;

  width: 755px;
  max-width: 100%;
  padding-top: 42px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 57px;
  overflow: hidden;

  background-color: #f4f0ed;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.name {
  display: flex;
  margin-bottom: 1.4rem;

  font-size: 2rem;
  font-weight: 600;
  line-height: 1.22;
}

.text {
  margin-bottom: 2.3rem;

  font-size: 1.5rem;
  line-height: 1.3852;

  color: #6a6a6a;
}

.grade {
  font-size: 1.5rem;
  line-height: 1.25;

  color: #0a0a0a;
}

@media (min-width: 768px) {
  .module {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1200px) {
  .module {
    padding-left: 100px;
    padding-right: 100px;
  }
}
