.food {
  &__content {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &__title {
    margin-bottom: 1.5rem;

    font-size: 3rem;
    font-weight: 600;
    line-height: 1.87;
    letter-spacing: -0.04em;
  }

  &__text {
    margin-bottom: 3.6rem;

    font-size: 1.8rem;
    line-height: 1.315;

    color: #0c0c0c;
  }

  &__images {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__small {
    position: relative;

    display: flex;
    width: calc(28% - 10px);
    padding-bottom: calc((28% - 10px) * 1.1);

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__big {
    position: relative;

    display: flex;
    width: calc(72% - 10px);
    padding-bottom: calc((72% - 10px) * 1.1);

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include media(md) {
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 3rem;
      grid-template-areas:
        'top top'
        'left right';

      padding-top: 8rem;
      padding-bottom: 8rem;
    }

    &__title {
      grid-area: top;
      margin-bottom: 3rem;

      font-size: 5.2rem;
      line-height: 1.08;
    }

    &__text {
      grid-area: left;
      margin-bottom: 0;
    }

    &__images {
      grid-area: right;
    }

    &__small {
      width: calc(28% - 12px);
    }

    &__big {
      width: calc(72% - 12px);
    }
  }

  @include media(xl) {
    &__content {
      grid-template-areas:
        'top right'
        'bottom right';
      grid-template-columns: 1fr 57%;
      grid-template-rows: 0.1fr 1fr;
      grid-column-gap: 12%;
      padding-top: 15.5rem;
      padding-bottom: 15.5rem;
    }

    &__title {
      grid-area: top;
      margin-bottom: 6.3rem;

      font-size: 6.8rem;
    }

    &__text {
      grid-area: bottom;
    }

    &__small {
      width: calc(28% - 24px);
      padding-bottom: calc((28% - 24px) * 1.1);
    }

    &__big {
      width: calc(72% - 24px);
      padding-bottom: calc((72% - 24px) * 1.1);
    }
  }
}
