.children {
  &__content {
    padding-bottom: 60px;
  }

  &__top {
    margin-bottom: 2rem;

    font-size: 1.6rem;
    line-height: 1.25;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    padding: 3rem;
    row-gap: 3.2rem;

    list-style: none;
    border: 1px solid rgba(178, 137, 117, 0.3);
  }

  &__item {
    p {
      position: relative;

      padding-top: 1.2rem;

      font-size: 1.6rem;
      line-height: 1.25;
      text-align: center;

      &::before {
        position: absolute;
        top: 0;
        left: 50%;

        width: 6rem;
        height: 0.3rem;

        background-color: map-get($colors, main);
        transform: translateX(-50%);

        content: '';
      }
    }

    span {
      font-weight: 800;
      color: map-get($colors, main);
    }
  }

  &__bottom {
    font-size: 1.6rem;
    line-height: 1.25;
    text-align: center;
  }

  @include media(md) {
    &__content {
      padding-bottom: 7.8rem;
    }

    &__top {
      font-size: 2rem;
    }

    &__list {
      flex-direction: row;
      justify-content: space-between;
      padding: 3.5rem;
    }

    &__item {
      width: 17.6rem;

      p {
        font-size: 2rem;
        text-align: left;

        &::before {
          left: 0;

          width: 8.2rem;

          transform: translateX(0);
        }
      }
    }

    &__bottom {
      font-size: 2rem;
    }
  }

  @include media(xl) {
    &__content {
      padding-bottom: 15.3rem;
    }

    &__list {
      column-gap: 6rem;
    }

    &__item {
      width: calc(33.33333% - 6rem / 2 * 3);
    }
  }
}
