:root {
  // scroll-behavior: smooth;
}

body {
  font-family: $font-family;

  color: map-get($colors, black);

  &.body-blocked {
    overflow: hidden;
  }
}

button {
  cursor: pointer;
}

.bg {
  background-size: cover;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  // box-shadow: none;
  outline: none;
  outline-offset: none;
}
