.map {
  height: 31rem;

  @include media(md) {
    height: 59.7rem;
  }

  @include media(xl) {
    height: 62.1rem;
  }
}
