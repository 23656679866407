.module {
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-bottom: 15px;

  background-color: #fff;
  box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);
}

.list {
  display: flex;
  flex-direction: column;
  margin-bottom: 18px;
  max-height: 200px;
  padding-right: 18px;
  overflow-y: scroll;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;

  font-size: 1.6rem;

  border-bottom: 1px solid rgba(178, 137, 117, 0.17);
}

div.select {
  width: 127px;
  height: 52px;

  background-position: right 0 center;

  select {
    font-size: 1.6rem;

    color: #b28975;
  }
}

.button {
  margin-right: 20px;
  height: 46px;

  font-size: 15px;

  border: none;
  color: #fff;
  background-color: #b28975;

  text-align: center;
  text-transform: uppercase;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#b28975, 10%);
  }
}
