.hero {
  padding-top: 50px;

  &__content {
    position: relative;

    padding-bottom: 77.5%;

    background-position: center;
    background-size: cover;
  }

  &__title {
    position: absolute;
    top: 8rem;
    left: 50%;

    display: flex;
    flex-direction: column;

    text-align: center;

    color: #fff;
    transform: translateX(-50%);

    strong {
      font-size: 3rem;
      font-weight: 700;
      line-height: 0.883;
      text-transform: uppercase;
      letter-spacing: -0.04em;
    }

    span {
      margin-bottom: 20px;

      font-size: 13px;
      font-weight: 500;
      line-height: 0.883;
    }

    b {
      padding-left: 5px;
      padding-right: 5px;

      font-size: 12px;
      font-weight: 400;
      line-height: 1.3;

      background-color: map-get($colors, main);
    }
  }

  &__buttons {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: -8.1rem;

    display: flex;
    flex-direction: column;
    padding-top: 2.3rem;
    padding-left: 4.8rem;
    padding-right: 4.8rem;

    background-color: #fff;
    transform: translateX(-50%);
  }

  &__booking {
    width: 15.2rem;
    height: 4.5rem;
    margin-bottom: 10px;

    font-size: 1.4rem;
    font-weight: 500;

    border: 1px solid map-get($colors, main);
    color: map-get($colors, main);
    background-color: transparent;

    transition: 0.2s;

    &:hover {
      color: #fff;
      background-color: map-get($colors, main);
    }
  }

  &__calculation {
    width: 15.2rem;
    height: 4.5rem;

    font-size: 1.4rem;
    font-weight: 500;

    border: none;
    color: #fff;
    background-color: map-get($colors, main);

    transition: 0.2s;

    &:hover {
      background-color: darken(map-get($colors, main), 10%);
    }
  }

  &__anchor {
    position: absolute;
    bottom: -3rem;
    left: 50%;

    width: 1.9rem;
    height: 1.9rem;

    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: translateX(-50%) rotate(-45deg);
  }

  @include media(md) {
    padding-top: 73px;

    &__content {
      padding-bottom: 85%;
    }

    &__title {
      position: absolute;
      top: 16.8rem;
      left: 50%;

      display: flex;
      flex-direction: column;

      text-align: center;

      color: #fff;
      transform: translateX(-50%);

      strong {
        font-size: 12rem;
        font-weight: 700;
        line-height: 0.883;
        text-transform: uppercase;
        letter-spacing: -0.04em;
      }

      span {
        margin-bottom: 20px;

        font-size: 52px;
        font-weight: 500;
        line-height: 0.883;
      }

      b {
        padding-left: 5px;
        padding-right: 5px;

        font-size: 19px;
        font-weight: 400;
        line-height: 1.3;

        background-color: map-get($colors, main);
      }
    }

    &__buttons {
      bottom: 0;

      flex-direction: row;
      padding-top: 2.7rem;
      padding-left: 4.7rem;
      padding-right: 4.7rem;
    }

    &__booking {
      width: 185px;
      height: 6.4rem;

      margin-right: 3.2rem;
      margin-bottom: 0;

      font-size: 1.6rem;
    }

    &__calculation {
      width: 185px;
      height: 6.4rem;

      font-size: 1.6rem;
    }
  }

  @include media(xl) {
    padding-top: 97px;

    &__content {
      padding-bottom: 48%;
    }

    &__buttons {
      padding: 3rem 5.4rem;
    }

    &__anchor {
      bottom: -6rem;

      width: 2.8rem;
      height: 2.8rem;
    }
  }
}
