.quiz {
  &__content {
    margin-left: -2.4rem;
    margin-right: -2.4rem;
    padding-bottom: 4rem;

    background-size: cover;
  }

  &__image {
    aspect-ratio: 1 / 0.76;
    margin-bottom: 20px;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    max-width: 27rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;

    font-size: 3rem;
    font-weight: 600;
    line-height: 0.93;
    text-align: center;
    letter-spacing: -0.04em;

    span {
      color: map-get($colors, main);
    }
  }

  &__block {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  @include media(sm) {
    &__content {
      margin-left: calc((100vw - 540px) / -2);
      margin-right: calc((100vw - 540px) / -2);
    }
  }

  @include media(md) {
    &__content {
      display: flex;
      margin-left: 0;
      margin-right: 0;
      padding-bottom: 0;
    }

    &__image {
      order: 1;
      aspect-ratio: 1/1.27;
      width: 52%;

      margin-bottom: 0;
    }

    &__block {
      width: 48%;
      padding-top: 6.5rem;
      padding-left: 3.3rem;
      padding-right: 4rem;
    }

    &__title {
      margin-left: 0;
      margin-right: 0;

      font-size: 3.6rem;
      text-align: left;
    }
  }

  @include media(xl) {
    &__image {
      aspect-ratio: 1 / 0.96;
    }

    &__block {
      padding-top: 7.5rem;
      padding-left: 9.8rem;
    }

    &__title {
      max-width: 40rem;
      margin-bottom: 4rem;

      font-size: 5rem;
    }

    #quiz {
      max-width: 27.3rem;
    }
  }
}
