.module {
  position: relative;

  width: 585px;
  max-width: 100%;
  padding-top: 57px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 68px;
  overflow: hidden;

  background-color: #f4f0ed;
}

button.close {
  position: absolute;
  top: 16px;
  right: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  max-width: 273px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  margin-bottom: 2.4rem;

  font-size: 3.2rem;
  font-weight: 600;
  line-height: 0.9;
  text-align: center;
  letter-spacing: -0.04em;
}

.name {
  margin-bottom: 1.5rem;
}

.phone {
  margin-bottom: 1.5rem;
}

.policy {
  margin-bottom: 1.5rem;
}

.button {
  align-self: center;
  width: 100%;
  height: 5.6rem;

  font-weight: 600;
  font-size: 1.6rem;
  text-align: center;

  border: none;
  color: #fff;
  background-color: #b28975;

  transition: background 0.2s;

  &:hover {
    background-color: darken(#b28975, 10%);
  }
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
}
