.footer {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4.5rem;
    padding-bottom: 3.3rem;
  }

  &__logo {
    width: 18.7rem;
    margin-bottom: 4.5rem;
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.4rem;
    margin-bottom: 5.3rem;

    a {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.25;
      text-decoration: none;

      color: #000;

      transition: 0.2s;

      &:hover {
        color: map-get($colors, main);
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__phone {
    margin-bottom: 1.4rem;

    font-size: 2rem;
    font-weight: 700;
    line-height: 1.25;
    text-decoration: none;

    color: map-get($colors, main);

    transition: 0.2s;

    &:hover {
      color: darken(map-get($colors, main), 10%);
    }
  }

  &__callback {
    width: 18rem;
    height: 5.6rem;

    font-size: 1.6rem;
    font-weight: 500;

    border: 1px solid map-get($colors, main);
    color: map-get($colors, main);
    background-color: transparent;

    transition: 0.2s;

    &:hover {
      color: #fff;
      background-color: darken(map-get($colors, main), 10%);
    }
  }

  &__bottom {
    background-color: map-get($colors, bg);
  }

  &__bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;

    button {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.25;

      border: none;
      color: rgba(0, 0, 0, 0.79);
      background-color: transparent;

      transition: 0.2s;

      &:hover {
        color: map-get($colors, main);
      }
    }
  }

  @include media(md) {
    &__content {
      position: relative;

      flex-direction: row;
      justify-content: space-between;
      padding-top: 3.7rem;
      padding-bottom: 7.4rem;
    }

    &__logo {
      width: 22rem;
      margin-bottom: 0;
    }

    &__nav {
      position: absolute;
      right: 0;
      bottom: 2rem;

      flex-direction: row;
      column-gap: 2.9rem;
      margin-bottom: 0;

      a {
        font-size: 1.6rem;
      }
    }

    &__info {
      flex-direction: row;
      align-items: center;
    }

    &__phone {
      margin-right: 2rem;
      margin-bottom: 0;
    }

    &__callback {
      width: 17rem;
    }

    &__bottom-content {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 1.5em;
      padding-bottom: 1.5em;

      button {
        font-size: 1.4rem;
      }
    }
  }

  @include media(xl) {
    &__content {
      justify-content: flex-start;
      padding-top: 4rem;
      padding-bottom: 5.4rem;
    }

    &__logo {
      margin-right: 8.4rem;
    }

    &__nav {
      position: static;

      column-gap: 5.4rem;
      margin-right: auto;

      a {
        font-size: 2rem;
      }
    }

    &__info {
      flex-direction: column;
      align-items: flex-end;
    }

    &__phone {
      margin-right: 0;
      margin-bottom: 1.6rem;
    }

    &__callback {
      width: 17.9rem;
    }

    &__bottom-content {
      padding-top: 2.9rem;
      padding-bottom: 2.9rem;

      button {
        font-size: 1.6rem;
      }
    }
  }
}
