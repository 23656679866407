@import '/src/styles/abstracts/functions';

$corner-color: #4c270f;

.module {
  position: relative;

  display: flex;
  align-items: center;
  height: 5.6rem;
  padding-left: 1.8rem;

  // background-image: svg-encode(
  //   '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"><polyline points="1 3, 6 8, 11 3" stroke="#{$corner-color}" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" fill="none" /></svg>'
  // );
  background-image: svg-encode(
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 10"><path xmlns="http://www.w3.org/2000/svg" d="M4 4L0 0H8L4 4Z" fill="#{$corner-color}" fill-opacity="0.3"/></svg>'
  );
  background-repeat: no-repeat;
  background-position: right 17px center;
  background-size: 10px;
  background-color: rgba(255, 255, 255, 0.79);
}

.title {
  font-size: 1.5rem;

  color: rgba(0, 0, 0, 0.69);
}

.select {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 14px;

  font-size: 1.5rem;

  border: none;
  background-color: transparent;
  cursor: pointer;

  appearance: none;
}
