#portal:not([data-count='0']) {
  position: fixed;
  z-index: 4000;
  top: 0;
  left: 0;

  display: grid;
  width: 100%;
  height: 100%;
  overflow: auto;

  & > section {
    &:not(:last-of-type) {
      // max-height: 100%;
      overflow: hidden;

      // opacity: 0;
    }
  }
}
