.module {
  height: 5.6rem;
  width: 100%;
  padding-left: 1.8rem;
  padding-right: 1.8rem;

  font-size: 1.5rem;

  border: none;
  background-color: rgba(255, 255, 255, 0.79);

  &::placeholder {
    color: #6a6a6a;
  }
}
