.contacts {
  position: relative;

  background-size: cover;

  #contacts {
    position: absolute;
    top: -5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 3.8rem;
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  &__phone {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      font-size: 2.8rem;
      font-weight: 800;
      line-height: 1.3852;
      text-decoration: none;

      color: #fff;
    }
  }

  &__address {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      display: flex;
      flex-direction: column;

      font-size: 2rem;
      line-height: 1.3852;
      text-align: center;

      color: #fff;
    }

    strong {
      font-weight: 700;
    }
  }

  &__email {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      display: flex;
      flex-direction: column;

      font-size: 2rem;
      line-height: 1.3852;
      text-align: center;

      color: #fff;
    }

    strong {
      font-weight: 700;
    }

    a {
      text-decoration: none;

      color: #fff;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    width: 4rem;
    height: 4rem;

    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
  }

  @include media(md) {
    #contacts {
      top: -7.3rem;
    }

    &__content {
      flex-direction: row;
      justify-content: space-between;
      padding-top: 5rem;
      padding-bottom: 5rem;
    }

    &__phone {
      align-items: flex-start;
      width: 22rem;

      a {
        font-size: 2rem;
      }
    }

    &__address {
      align-items: flex-start;
      width: 22rem;

      p {
        align-items: flex-start;
        text-align: left;
      }
    }

    &__email {
      align-items: flex-start;
      width: 22rem;

      p {
        text-align: left;
      }
    }
  }

  @include media(xl) {
    #contacts {
      top: -9.7rem;
    }

    &__content {
    }

    &__phone {
      width: 30rem;

      a {
        font-size: 2.8rem;
      }
    }

    &__address {
      width: 30rem;
    }

    &__email {
      width: 30rem;
    }
  }
}
