.question {
  background-repeat: no-repeat;
  background-size: 19.1rem;
  background-position: 110% 80%;
  background-color: map-get($colors, bg);

  &__content {
    padding-top: 8rem;
    padding-bottom: 6rem;
  }

  &__gallery {
    display: grid;
    grid-template-areas:
      'left top'
      'left bottom';
    grid-template-columns: calc(39% - 1rem) calc(61% - 1rem);
    grid-column-gap: 2rem;
    margin-bottom: 2.5em;
  }

  &__one {
    grid-area: left;
    align-self: center;
    aspect-ratio: 1/1.8;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__two {
    grid-area: top;
    width: 67%;
    aspect-ratio: 1/0.93;
    margin-bottom: 2rem;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__three {
    grid-area: bottom;
    aspect-ratio: 1/0.96;

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    margin-bottom: 2.6rem;

    max-width: 19rem;
    margin-left: auto;
    margin-right: auto;

    font-size: 3rem;
    font-weight: 600;
    line-height: 0.9;
    text-align: center;
    letter-spacing: -0.04em;

    span {
      color: map-get($colors, main);
    }
  }

  @include media(md) {
    background-size: 38.2rem;

    &__content {
      display: grid;
      grid-template-areas:
        'left top'
        'left bottom';
      grid-template-columns: calc(57% - 2.2rem) calc(43% - 2.2rem);
      grid-column-gap: 4.4rem;
      padding-bottom: 9.2rem;
    }

    &__gallery {
      grid-area: left;
      grid-template-columns: calc(39% - 1.2rem) calc(61% - 1.2rem);
      grid-column-gap: 2.4rem;
      margin-bottom: 0;
    }

    &__two {
      margin-bottom: 2.4em;
    }

    &__title {
      grid-area: top;
      max-width: none;

      font-size: 5.2rem;
      text-align: left;
      line-height: 1;
    }

    #question {
      grid-area: bottom;
    }
  }

  @include media(xl) {
    background-position: 80% 60%;

    &__content {
      grid-template-columns: calc(50% - 4.6rem) calc(50% - 4.6rem);
      grid-column-gap: 9.2rem;
      padding-top: 17.5rem;
      padding-bottom: 12.7rem;
    }

    &__gallery {
      grid-template-columns: calc(39% - 1.7rem) calc(61% - 1.7rem);
      grid-column-gap: 3.4rem;
    }

    &__two {
      margin-bottom: 3.4rem;
    }

    &__title {
      margin-bottom: 5.1rem;
      padding-top: 6rem;
      padding-right: 20rem;

      font-size: 6.8rem;
    }

    #question {
      max-width: 27.3rem;
    }
  }
}
