.rooms {
  position: relative;

  #rooms {
    position: absolute;
    top: -5rem;
  }

  &__content {
    padding-top: 6rem;
    padding-bottom: 2rem;
  }

  &__title {
    margin-bottom: 27px;

    font-size: 3rem;
    font-weight: 600;
    line-height: 0.9;
    text-align: center;
    letter-spacing: -0.04em;

    span {
      color: map-get($colors, main);
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    list-style: none;
  }

  &__item {
    background-color: map-get($colors, bg);

    &:nth-child(n + 5) {
      display: none;
    }
  }

  &__slider {
    position: relative;
  }

  &__slide {
    position: relative;

    display: flex;
    padding-bottom: 70%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    &::before {
      width: 2.9rem;
      height: 2.9rem;
      border-radius: 50%;
      background-color: #fff;

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 18px;

      width: 0.6rem;
      height: 0.6rem;

      border-left: 1px solid #686868;
      border-bottom: 1px solid #686868;
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0.5rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    &::before {
      width: 2.9rem;
      height: 2.9rem;
      border-radius: 50%;
      background-color: #fff;

      transition: 0.2s;

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 18px;

      width: 0.6rem;
      height: 0.6rem;

      border-left: 1px solid #686868;
      border-bottom: 1px solid #686868;
      transform: rotate(-135deg);

      content: '';
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }
  }

  &__pagination {
    position: absolute;
    z-index: 1;
    left: 50%;
    bottom: 1rem;

    display: flex;
    column-gap: 5px;

    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 5px;
      height: 5px;

      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.47);

      &-active {
        background-color: #fff;
      }
    }
  }

  &__body {
    padding-top: 2rem;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    padding-bottom: 0.7rem;
  }

  &__name {
    margin-bottom: 1.1rem;

    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.085;
    text-align: center;
    letter-spacing: -0.04em;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__prices {
    margin-bottom: 20px;

    text-align: center;

    del {
      margin-right: 1rem;

      font-size: 1.8rem;
      font-weight: 500;
      line-height: 1.28;

      color: rgba(178, 137, 117, 0.5);
    }

    strong {
      margin-right: 0.3rem;

      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1.28;

      color: map-get($colors, main);
    }

    span {
      font-size: 1.4rem;
    }
  }

  &__properties {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 11px;
    padding-top: 8px;
    padding-bottom: 8px;

    border-top: 1px solid rgba(76, 39, 15, 0.2);
    border-bottom: 1px solid rgba(76, 39, 15, 0.2);

    p {
      width: 50%;
    }

    strong {
      font-weight: 600;
      line-height: 1.315;
    }

    span {
      font-weight: 500;
      line-height: 1.315;

      color: map-get($colors, main);
    }
  }

  &__attributes {
    display: flex;
    flex-wrap: wrap;
    column-gap: calc((100% - 4rem * 6) / 5);
    row-gap: 1.3rem;

    margin-bottom: 1.1rem;

    p {
      position: relative;
      width: 4rem;
      height: 4rem;
      padding: 1rem;

      background-color: #fff;
      border-radius: 50%;

      &:nth-child(1),
      &:nth-child(7) {
        span {
          left: -10px;
          transform: translateX(0);
        }
      }

      &:nth-child(6),
      &:nth-child(12) {
        span {
          left: auto;
          right: -10px;
          transform: translateX(0);
        }
      }

      &:hover {
        span {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    span {
      position: absolute;
      z-index: 1;
      left: 50%;
      bottom: calc(100% + 10px);

      padding: 10px;

      font-size: 13px;
      white-space: nowrap;

      border-radius: 3px;
      color: map-get($colors, 'main');
      background-color: #fff;
      box-shadow: 0px 15px 21px rgba(66, 66, 106, 0.1);
      transform: translateX(-50%);
      visibility: hidden;
      opacity: 0;

      transition: 0.2s;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__calculation {
    width: calc(50% - 5px);
    height: 4.5rem;

    font-size: 1.2rem;
    font-weight: 500;

    border: none;
    color: #fff;
    background-color: map-get($colors, main);

    transition: 0.2s;

    &:hover {
      background-color: darken(map-get($colors, main), 10%);
    }
  }

  &__booking {
    width: calc(50% - 5px);
    height: 4.5rem;

    font-size: 1.2rem;
    font-weight: 500;

    border: 1px solid map-get($colors, main);
    color: map-get($colors, main);
    background-color: transparent;

    transition: 0.2s;

    &:hover {
      color: #fff;
      background-color: map-get($colors, main);
    }
  }

  &__more {
    display: block;
    width: 152px;
    height: 45px;
    margin-top: 2.8rem;
    margin-left: auto;
    margin-right: auto;

    font-size: 1.4rem;
    font-weight: 500;

    border: 1px solid map-get($colors, main);
    color: map-get($colors, main);
    background-color: transparent;

    transition: 0.2s;

    &:hover {
      color: #fff;
      background-color: map-get($colors, main);
    }
  }

  &_opened &__item {
    &:nth-child(n + 5) {
      display: block;
    }
  }

  &_opened &__more {
    display: none;
  }

  @include media(md) {
    #rooms {
      top: -7.3rem;
    }

    &__content {
      padding-bottom: 3rem;
    }

    &__title {
      margin-bottom: 5.2rem;

      font-size: 5.2rem;
      line-height: 1;
    }

    &__list {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 3rem;
    }

    &__item {
      width: calc(50% - 1.5rem);
    }

    &__pagination {
      column-gap: 8px;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
      }
    }

    &__body {
      padding-left: 1.3rem;
      padding-right: 1.3rem;
      padding-bottom: 1.4rem;
    }

    &__name {
      font-size: 2.3rem;
    }

    &__prices {
      margin-bottom: 2.2rem;
    }

    &__properties {
      margin-bottom: 1.2rem;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;

      p {
        font-size: 1.2rem;
      }
    }

    &__attributes {
      column-gap: calc((100% - 4.2rem * 6) / 5);
      margin-bottom: 1.6rem;

      p {
        width: 4.2rem;
        height: 4.2rem;
      }
    }

    &__calculation {
      height: 5.6rem;

      font-size: 1.6rem;
    }

    &__booking {
      height: 5.6rem;

      font-size: 1.6rem;
    }

    &__more {
      width: 21.5rem;
      height: 5.6rem;
      margin-top: 3rem;

      font-size: 1.6rem;
    }
  }

  @include media(xl) {
    #rooms {
      top: -9.7rem;
    }

    &__content {
      padding-top: 12.9rem;
      padding-bottom: 5rem;
    }

    &__title {
      margin-bottom: 9rem;

      font-size: 6.8rem;
    }

    &__item {
      width: calc(33.33333% - 3rem * 2 / 3);

      &:nth-child(n + 5) {
        display: block;
      }

      &:nth-child(n + 7) {
        display: none;
      }
    }

    &__slide {
      padding-bottom: 66%;
    }

    &__body {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      padding-bottom: 2.2rem;
    }

    &__properties {
      margin-bottom: 2.1rem;
    }

    &__attributes {
      margin-bottom: 2.1rem;
    }

    &__more {
      margin-top: 5rem;
    }

    &_opened &__item {
      &:nth-child(n + 7) {
        display: block;
      }
    }
  }

  @include media(xxl) {
    &__attributes {
      column-gap: calc((100% - 4.8rem * 6) / 5);

      p {
        width: 4.8rem;
        height: 4.8rem;
      }
    }
  }
}
