.pool {
  &__content {
    padding-bottom: 6rem;
  }

  &__title {
    margin-bottom: 1.5rem;

    font-size: 3rem;
    font-weight: 600;
    line-height: 1.87;
    letter-spacing: -0.04em;
  }

  &__text {
    margin-bottom: 3.6rem;

    font-size: 1.8rem;
    line-height: 1.315;

    color: #0c0c0c;
  }

  &__image {
    position: relative;

    display: flex;
    padding-bottom: 81%;

    img {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @include media(md) {
    &__content {
      display: grid;
      grid-template-areas:
        'left top'
        'left bottom';
      grid-template-columns: calc(66% - 15px) calc(34% - 15px);
      grid-template-rows: 0fr 1fr;
      align-content: start;
      grid-column-gap: 3rem;
      padding-bottom: 8rem;
    }

    &__title {
      grid-area: top;
      margin-bottom: 3.2rem;

      font-size: 5.2rem;
      line-height: 1;
    }

    &__text {
      grid-area: bottom;
      margin-bottom: 0;
    }

    &__image {
      grid-area: left;
      aspect-ratio: 1;
      padding-bottom: 0;
    }
  }

  @include media(xl) {
    &__content {
      grid-template-columns: calc(66% - 17px) calc(34% - 17px);
      grid-column-gap: 3.4rem;
      padding-bottom: 15.5rem;
    }

    &__title {
      margin-bottom: 6.3rem;

      font-size: 6.8rem;
    }

    &__image {
      aspect-ratio: 1 / 0.65;
    }
  }
}
