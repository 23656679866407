.container {
  // max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.4rem;
  padding-right: 2.4rem;

  @include media(sm) {
    max-width: map-get($container-max-widths, sm);
    padding-left: 0;
    padding-right: 0;
  }

  @include media(md) {
    max-width: map-get($container-max-widths, md);
  }

  @include media(lg) {
    max-width: map-get($container-max-widths, lg);
  }

  @include media(xl) {
    max-width: map-get($container-max-widths, xl);
  }

  @include media(xxl) {
    max-width: map-get($container-max-widths, xxl);
  }
}
