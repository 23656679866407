.reviews {
  &__content {
    padding-top: 6rem;
    padding-bottom: 5.8rem;
  }

  &__title {
    margin-bottom: 2.7rem;

    font-size: 3rem;
    font-weight: 600;
    line-height: 0.9;
    text-align: center;
  }

  &__slider {
    position: relative;
  }

  &__slide {
    padding-top: 3.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 3.5rem;

    background-color: map-get($colors, bg);
  }

  &__slide_clipped &__text {
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__slide_clipped &__button {
    visibility: visible;
  }

  &__name {
    margin-bottom: 1.4rem;

    font-size: 2rem;
    font-weight: 600;
    line-height: 1.25;
    letter-spacing: -0.04em;
  }

  &__text {
    font-size: 1.5rem;
    line-height: 1.3852;

    color: #6a6a6a;
  }

  &__button {
    margin-bottom: 2rem;

    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.25;

    border: none;
    color: map-get($colors, main);
    background-color: transparent;
    visibility: hidden;

    transition: 0.2s;

    &:hover {
      color: darken(map-get($colors, main), 10%);
    }
  }

  &__grade {
    font-size: 1.5rem;
    line-height: 1.3852;

    color: #0a0a0a;
  }

  &__prev {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: -2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    &::before {
      width: 3.4rem;
      height: 3.4rem;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 4px 10px rgba(76, 39, 15, 0.1);

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      left: 18px;

      width: 0.6rem;
      height: 0.6rem;

      border-left: 1px solid #686868;
      border-bottom: 1px solid #686868;
      transform: rotate(45deg);

      content: '';
    }

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  &__next {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: -2rem;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;

    border: none;
    background-color: transparent;
    transform: translateY(-50%);

    transition: 0.2s;

    &::before {
      width: 3.4rem;
      height: 3.4rem;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 4px 10px rgba(76, 39, 15, 0.1);

      content: '';
    }

    &::after {
      position: absolute;
      top: 17px;
      right: 18px;

      width: 0.6rem;
      height: 0.6rem;

      border-left: 1px solid #686868;
      border-bottom: 1px solid #686868;
      transform: rotate(-135deg);

      content: '';
    }

    &:hover {
      transform: translateY(-50%) scale(1.1);
    }
  }

  &__add {
    display: block;
    width: 152px;
    height: 45px;
    margin-top: 2.8rem;
    margin-left: auto;
    margin-right: auto;

    font-size: 1.4rem;
    font-weight: 500;

    border: 1px solid map-get($colors, main);
    color: map-get($colors, main);
    background-color: transparent;

    transition: 0.2s;

    &:hover {
      color: #fff;
      background-color: map-get($colors, main);
    }
  }

  @include media(md) {
    &__content {
      padding-top: 8rem;
      padding-bottom: 8.4rem;
    }

    &__title {
      margin-bottom: 4.2rem;

      font-size: 5.2rem;
      line-height: 1;
    }

    &__slide {
      padding-top: 4.2em;
      padding-left: 3.2rem;
      padding-right: 2.3rem;
      padding-bottom: 3.7rem;
    }

    &__button {
      margin-bottom: 1.4rem;
    }

    &__prev {
      width: 6rem;
      height: 6rem;

      &::before {
        width: 6rem;
        height: 6rem;
      }

      &::after {
        top: 24px;
        left: 26px;

        width: 1.2rem;
        height: 1.2rem;
        border-width: 2px;
      }
    }

    &__next {
      width: 6rem;
      height: 6rem;

      &::before {
        width: 6rem;
        height: 6rem;
      }

      &::after {
        top: 24px;
        right: 26px;

        width: 1.2rem;
        height: 1.2rem;
        border-width: 2px;
      }
    }

    &__add {
      width: 21.5rem;
      height: 5.6rem;
      margin-top: 3rem;

      font-size: 1.6rem;
    }
  }

  @include media(xl) {
    &__content {
      padding-top: 14.2rem;
      padding-bottom: 15.2rem;
    }

    &__title {
      margin-bottom: 9rem;

      font-size: 6.8rem;
    }

    &__slide {
      padding-left: 5.2rem;
      padding-right: 14.8rem;
      padding-bottom: 4.2rem;
    }

    &__add {
      margin-top: 5rem;
    }
  }
}
